import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import phao_hoa_qua from "../assets/phao_hoa_qua.png";
import qua_1 from "../assets/qua_1.png";
import qua_2 from "../assets/qua_2.png";
import qua_3 from "../assets/qua_3.png";
import qua_4 from "../assets/qua_4.png";
import qua_5 from "../assets/qua_5.png";
import qua_6 from "../assets/qua_6.png";
import qua_7 from "../assets/qua_7.png";
import qua_8 from "../assets/qua_8.png";
import Button from "../components/Button";
import { getUserData } from "../redux/slices/userSlices";

function Home() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const [isZooming, setIsZooming] = useState(false);

  const handleLuckydraw = useCallback(() => {
    navigate("/game/luckydraw");
  }, [navigate]);

  const onLogout = () => {
    navigate("/game/logout");
  };

  const onTakePhoto = () => {
    navigate("/game/takephoto");
  };

  useEffect(() => {
    if (dataUser.qua_id > 0) {
      setTimeout(() => setIsZooming(true), 500);
    } else {
      handleLuckydraw();
    }
  }, [dataUser, handleLuckydraw]);

  return (
    <>
      {dataUser.qua_id > 0 ? (
        <div className="flex flex-col px-5 py-16 mx-auto w-full font-bold text-center max-w-[480px]">
          <img
            loading="lazy"
            src={logo}
            className="w-full aspect-[5]"
            alt="logo"
          />
          <p className="font-normal w-full text-[#1E1F24] text-[14px] mt-8">
            Chúc mừng bạn nhận được quà tặng
          </p>
          <div className="relative w-full h-full">
            <img
              src={phao_hoa_qua}
              className={`z-0 absolute inset-0 w-full h-full object-cover transition-transform duration-1000 ${
                isZooming ? "scale-100" : "scale-0"
              }`}
              alt="phao_hoa"
            />
            <img
              loading="lazy"
              srcSet={
                dataUser.qua_id === 1
                  ? qua_1
                  : dataUser.qua_id === 2
                  ? qua_2
                  : dataUser.qua_id === 3
                  ? qua_3
                  : dataUser.qua_id === 4
                  ? qua_4
                  : dataUser.qua_id === 5
                  ? qua_5
                  : dataUser.qua_id === 6
                  ? qua_6
                  : dataUser.qua_id === 7
                  ? qua_7
                  : qua_8
              }
              className="relative mt-8 w-full z-10"
              alt="qua"
            />
          </div>
          <div className="font-medium w-full text-2xl text-green-400">
            {dataUser.qua_name}
          </div>
          <button
            onClick={onTakePhoto}
            className="font-bold justify-center items-center px-4 py-2 mt-8 w-full text-base text-white bg-blue-400 rounded-[10000px]"
          >
            Chụp hình
          </button>
          <button
            onClick={onLogout}
            className="font-bold justify-center items-center px-4 py-2 mt-8 w-full text-base text-white bg-green-400 rounded-[10000px]"
          >
            Xin Cảm Ơn
          </button>
        </div>
      ) : (
        <>
          <div className="flex flex-col justify-between px-5 py-16 mx-auto w-full max-w-[480px]">
            <img
              loading="lazy"
              src={logo}
              className="w-full aspect-[5]"
              alt="logo"
            />

            <div className="flex gap-4 justify-center px-6 mt-8 text-sm font-bold text-center text-black">
              <div className="mt-7 text-[#1E1F24] text-[14px] font-normal">
                Xin chào, {dataUser.name}
              </div>
            </div>
            <Button label="Quay thưởng" handleClick={handleLuckydraw} />
          </div>
        </>
      )}
    </>
  );
}

export default Home;
