import React, { useState, useRef, useEffect } from "react";
import { CHANNEL } from "../components/constants/constant";

interface LuckyWheelProps {
  onSpinStart: () => Promise<number>;
  onSpinEnd: () => void;
  wheelImage: string; // URL của hình ảnh vòng quay
  spinnerImage: string; // URL của hình ảnh spin ở giữa
}

const LuckyWheel: React.FC<LuckyWheelProps> = ({
  onSpinStart,
  onSpinEnd,
  wheelImage,
  spinnerImage,
}) => {
  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const wheelRef = useRef<HTMLDivElement>(null);

  const totalSegments = 8; // Số lượng múi trên vòng quay

  const spinWheel = async () => {
    if (isSpinning) {
      return;
    }
    setRotation(0);
    setIsSpinning(true);

    try {
      let resultId = await onSpinStart();
      // @ts-ignore
      //   if (CHANNEL === 4 && resultId === 8) {
      //     resultId = 1;
      //   }
      if (resultId > 0) {
        const segmentAngle = 360 / totalSegments;
        const targetRotation = 360 * 5 + (360 - (resultId - 1) * segmentAngle);

        setRotation((prevRotation) => prevRotation + targetRotation);

        setTimeout(() => {
          setIsSpinning(false);
          onSpinEnd();
        }, 4000); // Thời gian quay
      }
    } catch (error) {
      console.error("Error spinning wheel:", error);
      setIsSpinning(false);
    }
  };

  useEffect(() => {
    if (wheelRef.current) {
      wheelRef.current.style.transition = isSpinning
        ? "transform 4s cubic-bezier(0.25, 0.1, 0.25, 1)"
        : "none";
    }
  }, [isSpinning]);

  return (
    <div className="relative w-full h-full mx-auto">
      <div
        ref={wheelRef}
        className="w-full h-full rounded-full overflow-hidden"
        style={{
          backgroundImage: `url(${wheelImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          transform: `rotate(${rotation}deg)`,
        }}
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          onClick={spinWheel}
          src={spinnerImage}
          alt="Spinner"
          className="w-20 h-22" // Điều chỉnh kích thước phù hợp
        />
      </div>
    </div>
  );
};

export default LuckyWheel;
